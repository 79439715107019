import React from 'react';

export const YoutubeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.172" height="15.524" viewBox="0 0 22.172 15.524">
      <path
        className="fill-white"
        d="M21.715-3.653a2.778,2.778,0,0,0-1.954-1.954c-1.735-.475-8.675-.475-8.675-.475s-6.94,0-8.675.457A2.834,2.834,0,0,0,.457-3.653,29.268,29.268,0,0,0,0,1.68,29.161,29.161,0,0,0,.457,7.013,2.778,2.778,0,0,0,2.411,8.967c1.753.475,8.675.475,8.675.475s6.94,0,8.675-.457a2.778,2.778,0,0,0,1.954-1.954A29.277,29.277,0,0,0,22.172,1.7,27.783,27.783,0,0,0,21.715-3.653ZM8.876,5V-1.644L14.647,1.68Zm0,0"
        transform="translate(0 6.082)"
      />
    </svg>
  );
};

export default YoutubeIcon;
