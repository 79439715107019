import React from 'react';

export const OverlaySlider = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
      <defs>
        <linearGradient id="overlay-svg" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
      <rect className="overlay-svg" width="100%" height="100%" />
    </svg>
  );
};

export default OverlaySlider;
