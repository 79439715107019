import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { MENU_WEB } from '../../utils/menu';

const HeaderMenu = () => {
  const { t } = useTranslation('menu');

  return (
    <Grid container alignItems="center" className="menu-links" wrap="nowrap">
      {MENU_WEB.map((item, index) => (
        <Link href={item?.url} as={item?.url} shallow key={index}>
          <Button className="link-title menu-list-item">{t(item?.title)}</Button>
        </Link>
      ))}
    </Grid>
  );
};

export default HeaderMenu;
