export const MESSENGER_URL = 'https://m.me/';
export const DEFAULT_ID = '1';

export const FOOTER_SECTION_NAME = 'footer';
export const LayoutSections = [FOOTER_SECTION_NAME];

export const ONE_WAY = 'ONE_WAY';
export const ROUND_TRIP = 'ROUND_TRIP';
export const MULTI_DIRECTION = 'MULTI_DIRECTIONAL';

export const DEFAULT_PER_PAGE = 6;
export const DEFAULT_PER_PAGE_GALLERY = 3;
export const DEFAULT_PER_PAGE_PHOTO_LIBRARY_TYPE_1 = 6;
export const DEFAULT_PER_PAGE_PHOTO_LIBRARY_TYPE_2 = 9;
export const DEFAULT_PER_PAGE_PHOTO_LIBRARY_TYPE_3 = 12;
export const DEFAULT_PER_PAGE_FLIGHTS = 10;
export const DEFAULT_PAGE = 1;
export const DEFAULT_PER_PAGE_PRICES = 5;
export const ACTUALITY_PER_PAGE = 12;
export const NOT_FOUND_STATUS = 404;
export const DEFAULT_SCROLL_MOBILE_INFORMATION = 5;

export const MAX_SERVICE_DESCRIPTION = 100;

export const TRUE_VALUE = 'true';
export const FALSE_VALUE = 'false';

export const SERVICE_NOT_FOUND = 'SERVICE_NOT_FOUND';

export const SLIDER_PROMO = 1;
export const SLIDER_PUB = 2;

export const SLIDER_DELAY = 8000;

export const SERVICES_PAGE = 'service';

export const INFORMATION_TYPE = 1;
export const JASMIN_TYPE = 2;

export const INFORMATION_REDIRECTION_ONE_TYPE = 1;
export const INFORMATION_REDIRECTION_MULTI_TYPE = 2;

export const MAX_INFORMATION_URLS = 8;

export const CARD_TYPE_IMAGE_WITH_TEXTE = 3;
export const CARD_TYPE_WITH_BUTTON = 2;
export const CARD_TYPE_WITHOUT_BUTTON = 1;

export const DIRECTION_ASC = 'asc';
export const DIRECTION_DESC = 'desc';

export const DEFAULT_PAGINATION = 1;

export const MAX_PHONE_NUMBER = 8;
export const MAX_JOB = 100;
export const MAX_ZIP_CODE_NUMBER = 4;

export const MAX_JOB_DESCRIPTION = 120;

export const CLIENT_TYPE_CORPORATE = 2;

export const NETWORK_CARD_URL = 'https://www.nouvelair.com/fr/reseaux';
export const POINT_OF_SALES_URL = 'https://www.nouvelair.com/fr/point-de-vente';
export const OUR_DESTINATIONS_URL = 'https://www.nouvelair.com/fr/destinations';
export const TUNIS_AGENCY = '4040';

export const WEB_CONTACT_MAP_PADDING_TOP = 100;
export const WEB_CONTACT_MAP_PADDING_BOTTOM = 50;
export const WEB_CONTACT_MAP_PADDING_RIGHT = 50;
export const WEB_CONTACT_MAP_PADDING_LEFT = 150;

export const MOBILE_CONTACT_MAP_PADDING_TOP = 200;
export const MOBILE_CONTACT_MAP_PADDING_BOTTOM = 400;
export const MOBILE_CONTACT_MAP_PADDING_RIGHT = 10;
export const MOBILE_CONTACT_MAP_PADDING_LEFT = 10;

export const TUNISIA_LATITUDE = 36.833333333333336;
export const TUNISIA_LONGITUDE = 10.216666666666667;

export const WEB_MAP_HEIGHT = 550;
export const MOBILE_MAP_HEIGHT = 675;

export const DEFAULT_COUNTRY_CODE = 'TN';
export const IP_URL = 'https://ip.nf/me.json';

export const OPEN_MODE = {
  NONE: 1,
  NEW_TAB: 2,
  SAME_TAB: 3,
  POP_UP: 4,
};

export const GALLERY_TYPES = {
  SIMPLE: 1,
  PHOTO_LIBRARY: 2,
};
export const PRESS_NEWS_TAB = '0';
export const PHOTOTHEQUE_TAB = '1';

export const ENVIRONMENT_MODE = {
  PROD: 'production',
  DEV: 'developement',
};
