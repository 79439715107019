import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Grid, IconButton, Snackbar } from '@material-ui/core';
import GlobalContext from '../context/GlobalContext';
import CloseIcon from '@material-ui/icons/Close';
import OverlaySlider from '../components/ui/svg/OverlaySlider';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useRouter } from 'next/dist/client/router';
import { languages } from '../utils/languages';
import ReducerContext from '../context/ReducerContext';
import { useQuery } from 'react-query';
import { DTOError } from '../interfaces/DTO';
import Cookies from 'js-cookie';
import { LOCATION_ID } from '../utils/localStorage';
import { cryptValue, decryptValue } from '../helpers/helpers';
import { LocationInterface } from '../interfaces/location';
import { DEFAULT_COUNTRY_CODE } from '../utils/constants';
import { COUNTRY_TYPE } from '../context/reducers/types';
import { getCountry } from '../shared/countryMethods';
import { getSettings } from '../shared/settingsMethods';

type Props = {
  children?: ReactNode;
};

const Layout = ({ children }: Props) => {
  const gContext = useContext(GlobalContext);
  const router = useRouter();
  const currentLanguageCode = router.locale;
  const currentLanguage = languages.find((l) => l.code == currentLanguageCode);
  const { dispatch, state } = useContext(ReducerContext);
  const [isLoading, setIsloading] = useState(true);

  // fetching settings
  const { data: settings, isLoading: settingsLoading } = useQuery(['settings', currentLanguage?.id], () => getSettings(currentLanguage?.id), {
    enabled: !!currentLanguage?.id,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: 3,
    onError: (error: DTOError) => {
      if (error && error.status) {
        console.log('undefined');
        // gContext?.openSnackBar('error', t('undefined'));
      } else {
        console.log('network');
        // gContext?.openSnackBar('error', t('network'));
      }
      gContext?.setSettingsLoading(false);
      setIsloading(false);
    },
    select: (data) => data?.data,
    onSuccess: (data) => {
      const result = data || {};
      let setting = result && result.settings ? result.settings : {};
      const translations = result && result.translations && result.translations[0] ? result.translations[0] : {};

      setting = { ...setting, ...translations };
      gContext?.setSettings(setting || {});

      gContext?.setCountries(result?.locations || []);

      Cookies.set('setting', JSON.stringify(setting));
      gContext?.setSettingsLoading(false);
    },
  });

  // fetching countries
  const { isLoading: countryLoading, data: countryCode } = useQuery('country', getCountry, {
    enabled: !state.country,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    select: (data) => data?.ip?.country_code,
    onError: (error: DTOError) => {
      if (error && error.status) {
        console.log('undefined');
        // gContext?.openSnackBar('error', t('undefined'));
      } else {
        console.log('network');
        // gContext?.openSnackBar('error', t('network'));
      }
    },
  });

  useEffect(() => {
    const localLocationId = localStorage.getItem(LOCATION_ID);
    let foundLocation: LocationInterface = null;

    if (!settingsLoading && !countryLoading && settings) {
      // finding the currect location
      if (localLocationId) {
        foundLocation = settings.locations.find((location: LocationInterface) => location?.id === Number(decryptValue(localLocationId)));
      }
      if (!foundLocation) {
        if (countryCode) {
          foundLocation = settings.locations.find((location: LocationInterface) => location?.country_code === countryCode);
        } else {
          foundLocation = settings.locations.find(
            (location: LocationInterface) => location?.country_code?.toLowerCase() === DEFAULT_COUNTRY_CODE?.toLowerCase(),
          );
        }
      }
      if (!foundLocation) {
        foundLocation = settings?.locations[0] ? settings?.locations[0] : null;
      }

      // settign global emptyData
      if (foundLocation) {
        localStorage.setItem(LOCATION_ID, cryptValue(foundLocation?.id));
        dispatch({
          type: COUNTRY_TYPE,
          payload: foundLocation?.id,
        });
      }

      setIsloading(false);
    }
  }, [settings, countryCode]);

  return (
    <Grid>
      <Grid container direction="column">
        <Grid container direction="column" className="page-container">
          <div className="overlay-menu-layout">
            <OverlaySlider />
          </div>
          {/** <Question /> **/}
          <Header />
          {children}
          <Footer />
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          autoHideDuration={6000}
          className={`snack-bar-${gContext?.snackBarSevirity}`}
          open={gContext?.snackBar}
          onClose={() => gContext?.closeSnackBar()}
          message={gContext?.snackBarTxt}
          action={
            <>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => gContext?.closeSnackBar()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      </Grid>
    </Grid>
  );
};

export default Layout;
