import React from 'react';
import Loader from '../ui/svg/Loader';

export const Globalloader = () => {
  return (
    <div className="loader-container">
      <Loader />
    </div>
  );
};

export default Globalloader;
