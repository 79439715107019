// import App from 'next/app'
import React, { useEffect, useState } from 'react';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import { GlobalProvider } from '../context/GlobalContext';
import '../assets/sass/footer.scss';
import '../assets/sass/common.scss';
import '../assets/sass/home.scss';
import '../assets/sass/header.scss';
import '../assets/sass/menu.scss';
import '../assets/sass/loader.scss';
import '../assets/sass/slider.scss';
import '../assets/sass/question.scss';
import '../assets/sass/promotion.scss';
import '../assets/sass/404.scss';
import '../assets/sass/login.scss';
import '../assets/sass/newsletter.scss';
import '../assets/sass/list.scss';
import '../assets/sass/custom.scss';
import '../assets/sass/itemDetail.scss';
import '../assets/sass/gallery.scss';
import '../assets/sass/sitemap.scss';
import '../assets/sass/page.scss';
import '../assets/sass/table.scss';
import '../assets/sass/job.scss';
import '../assets/sass/jobDetail.scss';
import '../assets/sass/candidature.scss';
import '../assets/sass/timeLine.scss';
import '../assets/sass/information.scss';
import '../assets/sass/partner.scss';
import '../assets/sass/imageTextBloc.scss';
import '../assets/sass/contact.scss';
import '../assets/sass/network.scss';
import * as gtag from '../utils/gtag';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NextNProgress from 'nextjs-progressbar';
import Globalloader from '../components/Globalloader';
import Layout from '../Layout';
import { Divider, Grid } from '@material-ui/core';
import CookieConsent from 'react-cookie-consent';
import SecurityAirplane from '../components/ui/svg/SecurityAirplane';
import { COOKIES_POLICY_PAGE } from '../utils/pagesUrls';
import { AppProps } from 'next/app';
import { ReducerProvider } from '../context/ReducerContext';

const MyApp = ({ Component, pageProps, router }: AppProps) => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
          },
        },
      }),
  );
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation(['common', 'cookies']);
  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      gtag.pageview(url);
      window.scroll({ top: 0 });
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';

    // prevent the wheel click scrolling
    document.body.onmousedown = (e) => {
      if (e.button === 1) return false;
    };
    window.scroll({ top: 0 });
    const body = document.querySelector('body');
    body.style.height = '100vh';
    body.style.overflowY = 'hidden';
    setTimeout(() => {
      // stop loading
      setIsLoading(false);
      const container = document.querySelector('#page-container');
      container.classList.add('full-opacity');
      body.style.height = 'auto';
      body.style.overflowY = 'auto';
    }, 200);
  }, []);

  return (
    <>
      {isLoading && <Globalloader />}
      <Grid id="page-container">
        {router.pathname.match(/(login|_offline)/) ? (
          <>
            <NextNProgress
              options={{
                showSpinner: false,
              }}
            />
            <QueryClientProvider client={queryClient}>
              <Hydrate state={pageProps.dehydratedState}>
                <GlobalProvider>
                  <Component {...pageProps} />
                  <ReactQueryDevtools initialIsOpen={false} />
                </GlobalProvider>
              </Hydrate>
            </QueryClientProvider>
          </>
        ) : (
          <>
            <NextNProgress
              options={{
                showSpinner: false,
              }}
            />
            <QueryClientProvider client={queryClient}>
              <Hydrate state={pageProps.dehydratedState}>
                <GlobalProvider>
                  <ReducerProvider>
                    <Layout>
                      <Component {...pageProps} />
                    </Layout>
                    <ReactQueryDevtools initialIsOpen={false} />
                  </ReducerProvider>
                </GlobalProvider>
              </Hydrate>
            </QueryClientProvider>
          </>
        )}
      </Grid>
      {!isLoading && (
        <CookieConsent
          visible={router.pathname.match(/cookie-policy/) ? 'hidden' : 'byCookieValue'}
          overlay
          location="bottom"
          buttonText={t('common:accept')}
          cookieName="nouvelairCookies"
          containerClasses="cookies-consent-container"
          buttonClasses="cookies-consent-button"
          contentClasses="cookies-consent-content"
          buttonWrapperClasses="cookies-consent-button-container"
        >
          <Grid container alignItems="center" className="cookies-consent-info">
            <Grid item lg={2} md={2} sm={12} xs={12}>
              <Grid container alignItems="center" justifyContent="center" className="svg-container">
                <SecurityAirplane />
              </Grid>
            </Grid>
            <Grid item lg={10} md={10} sm={12} xs={12}>
              <h3>{t('cookies:bloc_title')}</h3>
              <Divider />
              <p
                dangerouslySetInnerHTML={{
                  __html: t('cookies:bloc_description', {
                    link: COOKIES_POLICY_PAGE,
                  }),
                }}
              ></p>
            </Grid>
          </Grid>
        </CookieConsent>
      )}
    </>
  );
};

export default appWithTranslation(MyApp);
