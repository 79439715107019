import React from 'react';

export const TwitterIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.127" height="18.252" viewBox="0 0 24 24">
      <path
        id="Sottrazione_3"
        data-name="Sottrazione 3"
        d="M12,24A12,12,0,0,1,3.515,3.515,12,12,0,1,1,20.485,20.485,11.922,11.922,0,0,1,12,24Zm-.589-9.82h0L14.86,19.2h4.78l-5.57-8.11L19.31,5H17.22L13.139,9.74,9.88,5H5.1l5.379,7.83L5,19.2H7.09l4.319-5.018Zm5.3,3.479h-.94L8.02,6.57H8.96l7.749,11.088Z"
        fill="#fff"
      />
    </svg>
  );
};

export default TwitterIcon;
