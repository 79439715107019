import React from 'react';

export const GooglePlayIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs xmlns="http://www.w3.org/2000/svg">
        <linearGradient id="first-bloc-google-linear" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#0067a2" />
          <stop offset="0.403" stopColor="#3d91a8" />
          <stop offset="0.805" stopColor="#74b7ae" />
          <stop offset="1" stopColor="#8ac6b1" />
        </linearGradient>
        <linearGradient id="second-bloc-google-linear" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#50b09b" />
          <stop offset="1" stopColor="#e9e486" />
        </linearGradient>
        <linearGradient id="third-bloc-google-linear" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#dc1b24" />
          <stop offset="0.228" stopColor="#c1203b" />
          <stop offset="0.748" stopColor="#8a2b6c" />
          <stop offset="1" stopColor="#753080" />
        </linearGradient>
        <linearGradient id="fourth-bloc-google-linear" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#eb7165" />
          <stop offset="0.003" stopColor="#eb7164" />
          <stop offset="0.675" stopColor="#f9a85e" />
          <stop offset="1" stopColor="#ffbe5c" />
        </linearGradient>
      </defs>
      <path d="m23 12c0 .75-.42 1.41-1.03 1.75l-5.2 2.89-4.4-4.64 4.4-4.64 5.2 2.89c.61.34 1.03 1 1.03 1.75z" className="first-bloc-google" />
      <path d="m12.37 12-10.8 11.39c-.36-.36-.57-.85-.57-1.39v-20c0-.54.21-1.03.57-1.39z" className="second-bloc-google" />
      <path d="m12.37 12 4.4 4.64c-13.173 7.225-13.725 8.307-15.2 6.75z" className="third-bloc-google" />
      <path d="m16.77 7.36-4.4 4.64-10.8-11.39c.618-.653 1.609-.796 2.4-.36z" className="fourth-bloc-google" />
    </svg>
  );
};

export default GooglePlayIcon;
