import moment from 'moment';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import padZeroPadding from 'crypto-js/pad-zeropadding';
import { envVariables } from '../config/config';

export const cryptApiKey = (apiKey: string) => {
  const timestamp = moment(new Date().toUTCString()).valueOf();
  const keyApi = apiKey + Math.floor(timestamp / 1000);
  const key = encHex.parse(envVariables.PRIVATE_KEY);
  const iv = encHex.parse(envVariables.PRIVATE_IV);
  const encrypted = aes.encrypt(keyApi, key, { iv: iv, padding: padZeroPadding }).toString();
  return encrypted;
};
