export const COOKIES_PAGE_DEFAULT = 'cookie-policy';
export const TECH_GROUPE_PAGE_DEFAULT = 'le-groupe-sabena-technics';
export const THE_GROUPE_PAGE_DEFAULT = 'le-groupe';
export const AWARDS_PAGE_DEFAULT = 'la-compagnie-awards';
export const REGULAR_NETWORK_PAGE_DEFAULT = 'la-compagnie-reseaux-reguilier';
export const CHARTER_NETWORK_PAGE_DEFAULT = 'la-compagnie-reseaux-charter';
export const TECH_FLOTTE_PAGE_DEFAULT = 'la-compagnie-technique-et-flotte';
export const NUMBERS_KEYS_PAGE_DEFAULT = 'la-compagnie-chiffres-cles';
export const COMPANY_PRESENTATION_PAGE_DEFAULT = 'la-compagnie-presentation';
export const THE_COMPANY_PAGE_DEFAULT = 'la-compagnie';
export const CATERING_GROUPE_PAGE_DEFAULT = 'le-groupe-tunisie-catering';
export const SFA_GROUPE_PAGE_DEFAULT = 'le-groupe-sfa';
export const ATCT_GROUPE_PAGE_DEFAULT = 'le-groupe-atct';
export const HANDLING_GROUPE_PAGE_DEFAULT = 'le-groupe-nouvelair-handling';
export const MAGAZINES_PAGE_DEFAULT = 'magazines';
export const PHOTO_SHOOTING_PAGE_DEFAULT = 'media-space-photos-shooting';
export const PHOTO_MANAGMENT_PAGE_DEFAULT = 'media-space-photos-management';
export const PHOTO_CABINE_PAGE_DEFAULT = 'media-space-photos-cabine';
export const PHOTO_ACTUALITIES_PAGE_DEFAULT = 'media-space-photos-actualites';
export const FLOTTE_MEMORIES_PAGE_DEFAULT = 'memories-flotte';
export const FOOTBALL_MEMORIES_PAGE_DEFAULT = 'memories-equipe-football';
export const ACMI_HISTORY_PAGE_DEFAULT = 'memories-historique-acmi';
export const PLANES_MEMORIES_PAGE_DEFAULT = 'memories-avions';
export const HOTESSES_UNIFORM_PAGE_DEFAULT = 'memories-tenues-hotesses';
export const KEY_DATES_MEMORIES_PAGE_DEFAULT = 'memories-dates-cles';
export const MEMORIES_PAGE_DEFAULT = 'memories';

export const DEFAULT_PAGES = [
  COOKIES_PAGE_DEFAULT,
  TECH_GROUPE_PAGE_DEFAULT,
  THE_GROUPE_PAGE_DEFAULT,
  AWARDS_PAGE_DEFAULT,
  REGULAR_NETWORK_PAGE_DEFAULT,
  CHARTER_NETWORK_PAGE_DEFAULT,
  TECH_FLOTTE_PAGE_DEFAULT,
  NUMBERS_KEYS_PAGE_DEFAULT,
  COMPANY_PRESENTATION_PAGE_DEFAULT,
  THE_COMPANY_PAGE_DEFAULT,
  CATERING_GROUPE_PAGE_DEFAULT,
  SFA_GROUPE_PAGE_DEFAULT,
  ATCT_GROUPE_PAGE_DEFAULT,
  HANDLING_GROUPE_PAGE_DEFAULT,
  MAGAZINES_PAGE_DEFAULT,
  PHOTO_SHOOTING_PAGE_DEFAULT,
  PHOTO_MANAGMENT_PAGE_DEFAULT,
  PHOTO_CABINE_PAGE_DEFAULT,
  PHOTO_ACTUALITIES_PAGE_DEFAULT,
  FLOTTE_MEMORIES_PAGE_DEFAULT,
  FOOTBALL_MEMORIES_PAGE_DEFAULT,
  ACMI_HISTORY_PAGE_DEFAULT,
  PLANES_MEMORIES_PAGE_DEFAULT,
  HOTESSES_UNIFORM_PAGE_DEFAULT,
  KEY_DATES_MEMORIES_PAGE_DEFAULT,
  MEMORIES_PAGE_DEFAULT,
];
