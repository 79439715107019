export const PAGES_URLS_URL = 'urls';
export const LOGIN_URL = 'admin/login';
export const LOGIN_APP_URL = 'api/login';
export const SUBSCRIBE_NL_URL = 'newsletter/subscribe';
export const ACTUALITIES_URL = 'actuality/corporate';
export const PAGE_CONTENT_URL = 'page/corporate/details';
export const PAGE_CONTENT_PREVIEW_URL = 'previews/corporate/page';
export const PAGINATED_GALLERY_PAGE_URL = 'page/galleries';
export const PAGINATED_GALLERY_PAGE_PREVIEW_URL = 'previews/page/galleries';
export const PAGINATED_GALLERY_DESTINATION_URL = 'destinations/galleries';
export const PAGINATED_GALLERY_DESTINATION_PREVIEW_URL = 'previews/destination/galleries';
export const FAQ_LIST_URL = 'faqs/categories';
export const SEO_DATA_URL = 'seo/details';
export const ALL_PAGES_URL = 'page/corporate';
export const FILE_URL = 'file';
export const INFORMATION_URL = 'information';
export const MODULE_COLLAPSES_URL = 'page/collapse';
export const CORPATE_DATA_URL = 'corporate';
export const JOB_URL = 'job_offer/';
export const JOB_DETAILS_URL = 'job_offer/details';
export const NATIONALITY_URL = 'nationality';
export const ADD_JOB_URL = 'job_application';
export const ACTUALITY_DETAIL_URL = 'actuality/corporate/details';
export const PARTNERS_URL = 'partner';
export const AGENCIES_URL = 'agencies';
export const SETTINGS_URL = 'settings';
export const PHOTO_LIBRARY_GROUP_URL = 'photo_library';
