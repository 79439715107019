import React from 'react';

export const InstagramIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.217" height="17.217" viewBox="0 0 17.217 17.217">
      <path
        className="fill-white"
        d="M12.557,0h-7.9A4.666,4.666,0,0,0,0,4.661v7.9a4.666,4.666,0,0,0,4.661,4.66h7.9a4.666,4.666,0,0,0,4.66-4.66v-7.9A4.666,4.666,0,0,0,12.557,0ZM8.609,13.316a4.708,4.708,0,1,1,4.708-4.708A4.713,4.713,0,0,1,8.609,13.316Zm4.82-8.305A1.391,1.391,0,1,1,14.82,3.62,1.393,1.393,0,0,1,13.429,5.011Zm0,0"
      />
      <path
        className="fill-white"
        d="M149.718,146.02a3.7,3.7,0,1,0,3.7,3.7A3.7,3.7,0,0,0,149.718,146.02Zm0,0"
        transform="translate(-141.109 -141.109)"
      />
      <path
        className="fill-white"
        d="M388.374,96.3a.382.382,0,1,0,.382.382A.382.382,0,0,0,388.374,96.3Zm0,0"
        transform="translate(-374.945 -93.063)"
      />
    </svg>
  );
};

export default InstagramIcon;
