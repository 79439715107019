import React from 'react';

export const Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="192" height="32.997" viewBox="0 0 192 32.997" className="logo-icon">
      <g transform="translate(-160 -198)">
        <g transform="translate(160 198)">
          <g transform="translate(0 0)">
            <path
              className="fill-white logo-blue"
              d="M75.175,188.332C58.2,185.465,49.739,205.7,36.029,207.418c-9.988,1.258-17.237-5.9-18.469-11.143-.736,10.167,8.49,17.417,18.717,16.954C53.437,212.441,62.543,191.533,75.175,188.332Z"
              transform="translate(-17.518 -180.253)"
            />
            <path
              className="fill-white logo-blue"
              d="M87.965,186.067c-12.958-1.78-16,7.805-26.454,11.237-6.753,2.217-11.922.582-15.611-3.6,1.72,5.495,8.353,9.423,14.2,8.944C71.593,201.7,78.08,188.976,87.965,186.067Z"
              transform="translate(-43.471 -178.236)"
            />
            <g transform="translate(4.98)">
              <path
                className="fill-white logo-yellow"
                d="M87.75,113.5a22.413,22.413,0,0,0,5.726-2.841c1.2-.8,5.426-3.817,7.574-5.118A13.06,13.06,0,0,0,75.7,109.96a13.515,13.515,0,0,0,.12,1.78A12.873,12.873,0,0,0,87.75,113.5Z"
                transform="translate(-75.7 -96.9)"
              />
            </g>
          </g>
          <g transform="translate(53.652 10.424)">
            <path
              className="fill-white logo-blue"
              d="M647.053,267.534h4.861l-.377,1.84h.051a8.1,8.1,0,0,1,2.456-1.558,8.663,8.663,0,0,1,3.381-.616,7.306,7.306,0,0,1,2.114.282,3.64,3.64,0,0,1,1.532.873,3.03,3.03,0,0,1,.822,1.464,4.855,4.855,0,0,1-.009,2.08l-1.746,8.43h-5.007l1.412-6.915c.342-1.618-.342-2.431-2.08-2.431a4,4,0,0,0-2.388.745,3.65,3.65,0,0,0-1.369,2.328l-1.3,6.282H644.4Z"
              transform="translate(-644.4 -263.049)"
            />
            <path
              className="fill-white logo-blue"
              d="M862.57,271.824a7.78,7.78,0,0,1,3.364-5.186,12.726,12.726,0,0,1,7.052-1.737q4.391,0,6.316,1.737t1.215,5.186a7.76,7.76,0,0,1-3.364,5.186,12.715,12.715,0,0,1-7.044,1.737q-4.391,0-6.316-1.737C862.5,275.855,862.1,274.126,862.57,271.824Zm9.722-3.612a4.679,4.679,0,0,0-2.884.882,4.316,4.316,0,0,0-1.558,2.73,3.022,3.022,0,0,0,.428,2.73,3.207,3.207,0,0,0,2.5.882,4.661,4.661,0,0,0,2.884-.882,4.315,4.315,0,0,0,1.558-2.73,3.016,3.016,0,0,0-.428-2.73A3.225,3.225,0,0,0,872.292,268.212Z"
              transform="translate(-843.724 -260.946)"
            />
            <path
              className="fill-white logo-blue"
              d="M1090.894,271.009h5.007l-1.369,6.607a2.8,2.8,0,0,0,.188,2.277,2.159,2.159,0,0,0,1.8.65,4,4,0,0,0,2.388-.745,3.628,3.628,0,0,0,1.37-2.328l1.344-6.47h5.007l-2.645,12.795h-4.861l.334-1.652h-.051a7.943,7.943,0,0,1-2.456,1.558,8.649,8.649,0,0,1-3.372.616,7.479,7.479,0,0,1-2.088-.274,3.468,3.468,0,0,1-1.532-.873,3.14,3.14,0,0,1-.813-1.592,6.224,6.224,0,0,1,.068-2.431Z"
              transform="translate(-1050.995 -266.524)"
            />
            <path
              className="fill-white logo-blue"
              d="M1305.1,271.1h5.692l1.327,8.43,4.818-8.43h5.691l-8.55,12.795h-5.717Z"
              transform="translate(-1248.554 -266.615)"
            />
            <path
              className="fill-white logo-blue"
              d="M1508.387,274.517a5.956,5.956,0,0,1-1.155,1.566,7.655,7.655,0,0,1-1.9,1.378,11.515,11.515,0,0,1-2.713.993,15.735,15.735,0,0,1-3.62.377c-2.884,0-4.981-.565-6.308-1.686s-1.729-2.876-1.224-5.255a7.929,7.929,0,0,1,1.25-2.987,8.044,8.044,0,0,1,2.242-2.157,10.156,10.156,0,0,1,3.073-1.309,15.065,15.065,0,0,1,3.706-.436,12.656,12.656,0,0,1,3.6.462,5.915,5.915,0,0,1,2.5,1.4,4.693,4.693,0,0,1,1.275,2.4,8.132,8.132,0,0,1-.111,3.458l-.094.377h-12.564a3.358,3.358,0,0,0,.145,1.147,1.978,1.978,0,0,0,.582.907,2.976,2.976,0,0,0,1.07.591,5.3,5.3,0,0,0,1.635.214,4.036,4.036,0,0,0,1.986-.436,6.047,6.047,0,0,0,1.344-1ZM1504.3,270.5a3.263,3.263,0,0,0-.154-1,1.977,1.977,0,0,0-.531-.839,2.576,2.576,0,0,0-.976-.565,4.44,4.44,0,0,0-1.5-.214,4.51,4.51,0,0,0-2.8.779,4.321,4.321,0,0,0-1.455,1.84Z"
              transform="translate(-1418.78 -261.037)"
            />
            <path className="fill-white logo-blue" d="M1707.086,218.7h5.007l-3.586,17.28H1703.5Z" transform="translate(-1612.857 -218.7)" />
            <path
              className="fill-white logo-blue"
              d="M1788.3,271.548a5.517,5.517,0,0,1,1.369-2.277,6.3,6.3,0,0,1,2.08-1.327,9.7,9.7,0,0,1,2.576-.6,28.494,28.494,0,0,1,2.876-.145,26.2,26.2,0,0,1,3.184.18,7.934,7.934,0,0,1,2.456.65,3.055,3.055,0,0,1,1.438,1.318,3.238,3.238,0,0,1,.163,2.2l-1.344,6.47a6.093,6.093,0,0,0-.162,1.3,1.877,1.877,0,0,0,.06.5c.034.154.094.334.154.522h-5.007a2.231,2.231,0,0,1-.068-.591c0-.111,0-.214.009-.317a2.366,2.366,0,0,1,.034-.274,6.763,6.763,0,0,1-2.884,1.369,16.918,16.918,0,0,1-3.492.334q-6.226,0-5.426-3.988a5.133,5.133,0,0,1,.753-1.84,4.223,4.223,0,0,1,1.309-1.241,6.218,6.218,0,0,1,1.874-.745,14.985,14.985,0,0,1,2.456-.351l5.049-.334a2.538,2.538,0,0,0,1.3-.3,1.16,1.16,0,0,0,.428-.745.872.872,0,0,0-.539-1.027,5.606,5.606,0,0,0-2.336-.342,4.931,4.931,0,0,0-2.2.394,2.245,2.245,0,0,0-1.113,1.215h-5Zm10.7,2.807q-.308.09-.8.18c-.325.051-.719.111-1.19.18l-3.475.4a2.364,2.364,0,0,0-1.181.4,1.445,1.445,0,0,0-.522.9,1.036,1.036,0,0,0,.368,1.155,2.553,2.553,0,0,0,1.549.4,7.064,7.064,0,0,0,3.5-.745,3.013,3.013,0,0,0,1.575-2.088Z"
              transform="translate(-1688.495 -263.049)"
            />
            <path
              className="fill-white logo-blue"
              d="M2008.545,223.185h5.006l-2.645,12.795H2005.9Zm.941-4.485h5.007l-.642,3.021h-5.007Z"
              transform="translate(-1889.376 -218.7)"
            />
            <path
              className="fill-white logo-blue"
              d="M2093.144,267.625h4.938l-.521,2.525h.043a7.694,7.694,0,0,1,2.559-2.063,6.983,6.983,0,0,1,3.244-.787c.265,0,.556.009.865.034s.582.06.813.1l-.967,4.673a5.185,5.185,0,0,0-1.215-.308,9.929,9.929,0,0,0-1.472-.12,5.03,5.03,0,0,0-3.338,1,4.765,4.765,0,0,0-1.567,2.773l-1.018,4.955H2090.5Z"
              transform="translate(-1966.735 -263.141)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
