import React, { useState } from 'react';
import { OtherSettingsInterface } from '../interfaces/setting';
import { LocationInterface } from '../interfaces/location';

export interface ContextProps {
  snackBar: boolean;
  openSnackBar: (sevirity: string, text: string) => void;
  closeSnackBar: () => void;
  snackBarSevirity: string;
  snackBarTxt: string;
  countries: Array<LocationInterface>;
  setCountries: (value: Array<LocationInterface>) => void;
  setSettings: (value: OtherSettingsInterface | null) => void;
  settings: OtherSettingsInterface;
  settingsLoading: boolean;
  setSettingsLoading: (value: boolean) => void;
}

export const GlobalContext = React.createContext<ContextProps | null>(null);

type Props = {
  children: React.ReactNode;
};

const GlobalProvider = ({ children }: Props) => {
  const [snackBar, setSnackBar] = useState<boolean>(false);
  const [snackBarSevirity, setSnackBarSevirity] = useState<string>('');
  const [snackBarTxt, setSnackBarTxt] = useState<string>('');
  const [settingsLoading, setSettingsLoading] = useState(true);
  const [countries, setCountries] = useState<Array<LocationInterface>>([]);
  const [settings, setSettings] = useState<OtherSettingsInterface | null>(null);

  const openSnackBar = (sevirity: string, text: string) => {
    setSnackBar(true);
    setSnackBarTxt(text);
    setSnackBarSevirity(sevirity);
  };
  const closeSnackBar = () => {
    setSnackBar(false);
    setSnackBarTxt('');
    setSnackBarSevirity('');
  };

  return (
    <GlobalContext.Provider
      value={{
        snackBar,
        snackBarSevirity,
        snackBarTxt,
        openSnackBar,
        closeSnackBar,
        setCountries,
        countries,
        setSettings,
        settings,
        settingsLoading,
        setSettingsLoading,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };
