export const UAParser = require('ua-parser-js/dist/ua-parser.min');

export const DeviceTypes = {
  Mobile: 'mobile',
  Tablet: 'tablet',
  SmartTv: 'smarttv',
  Console: 'console',
  Wearable: 'wearable',
  Browser: undefined,
};

export const OsTypes = {
  IOS: 'iOS',
  Android: 'Android',
  WindowsPhone: 'Windows Phone',
  Windows: 'Windows',
  MAC_OS: 'Mac OS',
};

export const isMobile = () => {
  const UA = new UAParser();
  const device = UA.getDevice();
  return device.type === DeviceTypes.Mobile;
};

export const isTablet = () => {
  const UA = new UAParser();
  const device = UA.getDevice();
  return device.type === 'tablet';
};

export const isBrowser = () => {
  const UA = new UAParser();
  const device = UA.getDevice();
  return device.type === undefined;
};

export const isIOS = () => {
  const UA = new UAParser();
  const os = UA.getOS();
  return os.name === OsTypes.IOS;
};

export const isAndroid = () => {
  const UA = new UAParser();
  const os = UA.getOS();
  return os.name === OsTypes.Android;
};
