import React from 'react';
import { Button, Grid, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { espacePro } from '../../utils/espacePro';

const SubMenuSelector = () => {
  const { t } = useTranslation('common');
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const redirectTo = (url: string) => {
    window.open(url, '_blank');
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  return (
    <Grid className="language-selector">
      <Grid alignItems="center" container>
        <Button
          className="language-btn"
          style={{ minWidth: 100 }}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleOpen}
          onMouseOver={() => handleOpen()}
          onMouseLeave={() => handleClose()}
        >
          <span className="language-menu-abv">{t('menu:pro_access')}</span>
          {open ? <ExpandLessIcon style={{ fontSize: '12px' }} /> : <ExpandMoreIcon style={{ fontSize: '12px' }} />}
          {open && <div className="popper-style"></div>}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal={false}
          className="language-dropdown menu-dropdown"
          onMouseOver={handleOpen}
          onMouseLeave={handleClose}
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
            },
          }}
          placement="bottom"
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'bottom',
              }}
            >
              <Paper>
                <Grid container direction="column" className="languages-list">
                  <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {espacePro.map((item, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          handleClose();
                          redirectTo(item?.url);
                        }}
                      >
                        {t(item?.title)}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Grid>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

export default SubMenuSelector;
