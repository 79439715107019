import React from 'react';

export const FacebookIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9.127" height="18.252" viewBox="0 0 9.127 18.252">
      <path
        className="fill-white"
        d="M13.648,3.031h1.666V.129A21.516,21.516,0,0,0,12.886,0c-2.4,0-4.048,1.511-4.048,4.289V6.845H6.187v3.244H8.838v8.163h3.25V10.09h2.544l.4-3.244H12.088V4.61c0-.938.253-1.58,1.56-1.58Z"
        transform="translate(-6.187)"
      />
    </svg>
  );
};

export default FacebookIcon;
