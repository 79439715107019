import { FooterSectionsInterface } from '../interfaces';
import { NETWORK_CARD_URL, OUR_DESTINATIONS_URL, POINT_OF_SALES_URL } from './constants';
import {
  ACMI_HISTORY_PAGE_DEFAULT,
  ATCT_GROUPE_PAGE_DEFAULT,
  AWARDS_PAGE_DEFAULT,
  CATERING_GROUPE_PAGE_DEFAULT,
  CHARTER_NETWORK_PAGE_DEFAULT,
  COMPANY_PRESENTATION_PAGE_DEFAULT,
  FLOTTE_MEMORIES_PAGE_DEFAULT,
  FOOTBALL_MEMORIES_PAGE_DEFAULT,
  HANDLING_GROUPE_PAGE_DEFAULT,
  HOTESSES_UNIFORM_PAGE_DEFAULT,
  KEY_DATES_MEMORIES_PAGE_DEFAULT,
  NUMBERS_KEYS_PAGE_DEFAULT,
  PLANES_MEMORIES_PAGE_DEFAULT,
  REGULAR_NETWORK_PAGE_DEFAULT,
  SFA_GROUPE_PAGE_DEFAULT,
  TECH_FLOTTE_PAGE_DEFAULT,
  TECH_GROUPE_PAGE_DEFAULT,
  THE_GROUPE_PAGE_DEFAULT,
  MEMORIES_PAGE_DEFAULT,
  MAGAZINES_PAGE_DEFAULT,
} from './defaultPagesUrls';
import { CARRIER_PAGE, CONTACT_PAGE, Home_PAGE, PRESSE_NEWS_PAGE } from './pagesUrls';

export const acceuilSection: FooterSectionsInterface = {
  items: [
    { title: 'common:homepage', url: Home_PAGE },
    { title: 'footer:memories', url: `/${MEMORIES_PAGE_DEFAULT}` },
    { title: 'footer:media_center', url: `/${PRESSE_NEWS_PAGE}` },
    { title: 'footer:carriers_emploi', url: CARRIER_PAGE },
    { title: 'menu:magazines', url: `/${MAGAZINES_PAGE_DEFAULT}` },
  ],
};

export const companySection: FooterSectionsInterface = {
  title: 'footer:company',
  items: [
    { title: 'footer:presentation', url: `/${COMPANY_PRESENTATION_PAGE_DEFAULT}` },
    { title: 'footer:keys_numbers', url: `/${NUMBERS_KEYS_PAGE_DEFAULT}` },
    { title: 'footer:flotte_tech', url: `/${TECH_FLOTTE_PAGE_DEFAULT}` },
    { title: 'footer:network_charter', url: `/contenu/reseau-charter-et-regulier` },
    // { title: 'footer:network_regulary', url: `/${REGULAR_NETWORK_PAGE_DEFAULT}` },
    { title: 'footer:awards', url: `/${AWARDS_PAGE_DEFAULT}` },
    { title: 'footer:the_groupe_title', url: `/${THE_GROUPE_PAGE_DEFAULT}` },
  ],
};

export const groupeSection: FooterSectionsInterface = {
  title: 'footer:the_groupe_title',
  items: [
    { title: 'footer:sabena_groupe', url: `/${TECH_GROUPE_PAGE_DEFAULT}` },
    { title: 'footer:groupe_handling', url: `/${HANDLING_GROUPE_PAGE_DEFAULT}` },
    { title: 'footer:atct_groupe', url: `/${ATCT_GROUPE_PAGE_DEFAULT}` },
    { title: 'footer:sfa_groupe', url: `/${SFA_GROUPE_PAGE_DEFAULT}` },
    { title: 'footer:tunisia_catering', url: `/${CATERING_GROUPE_PAGE_DEFAULT}` },
  ],
};

export const memoriesSection: FooterSectionsInterface = {
  title: 'footer:memories',
  items: [
    { title: 'footer:dates_keys', url: `/${KEY_DATES_MEMORIES_PAGE_DEFAULT}` },
    { title: 'footer:hotessess_uniform', url: `/${HOTESSES_UNIFORM_PAGE_DEFAULT}` },
    { title: 'footer:airplanes', url: `/${PLANES_MEMORIES_PAGE_DEFAULT}` },
    { title: 'footer:acmi_history', url: `/${ACMI_HISTORY_PAGE_DEFAULT}` },
    { title: 'footer:football_team', url: `/${FOOTBALL_MEMORIES_PAGE_DEFAULT}` },
    { title: 'footer:flotte', url: `/${FLOTTE_MEMORIES_PAGE_DEFAULT}` },
  ],
};

export const mediaCenterSection: FooterSectionsInterface = {
  title: 'footer:media_center',
  items: [{ title: 'footer:presse_news', url: PRESSE_NEWS_PAGE }],
};

export const discoverSection: FooterSectionsInterface = {
  title: 'footer:discover',
  items: [
    { title: 'footer:network_card', url: NETWORK_CARD_URL },
    { title: 'footer:points_of_sale', url: POINT_OF_SALES_URL },
    { title: 'footer:our_destinations', url: OUR_DESTINATIONS_URL },
  ],
};

export const extraSection: FooterSectionsInterface = {
  title: 'common:see_extra',
  items: [{ title: 'common:contact', url: CONTACT_PAGE }],
};

export const homeSection: FooterSectionsInterface = {
  title: 'common:homepage',
  url: Home_PAGE,
  items: [],
};

export const homeSitemapSection: FooterSectionsInterface = {
  title: 'common:home_page_sitemap',
  url: Home_PAGE,
  items: [],
};
