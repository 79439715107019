/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { envVariables } from '../config/config';
import { cryptApiKey } from './cryptXAPIKey';

const Client: AxiosInstance = axios.create({
  baseURL: envVariables.URL_API,
  headers: {
    Accept: 'application/json',
    'X-API-Key': cryptApiKey(envVariables.PRIVATE_X_API_KEY),
  },
});

// POST action
const post = async (url: string, data) => {
  try {
    Client.defaults.headers['X-API-Key'] = cryptApiKey(envVariables.PRIVATE_X_API_KEY);

    const response = await Client.post(url, data);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// GET action
const getAbsolute = async (url: string) => {
  try {
    Client.defaults.headers['X-API-Key'] = cryptApiKey(envVariables.PRIVATE_X_API_KEY);
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// GET action, the token and the params ar not required
const get = async (url: string, token?: string, params?: AxiosRequestConfig) => {
  try {
    Client.defaults.headers['X-API-Key'] = cryptApiKey(envVariables.PRIVATE_X_API_KEY);
    if (token) {
      Client.defaults.headers['authorization'] = 'Bearer ' + token;
    } else {
      delete Client.defaults.headers['authorization'];
    }
    const response = await Client.get(url, params);

    return response.data;
  } catch (err) {
    throw err?.response;
  }
};

// PATCH action
const patch = async (url: string, data) => {
  try {
    Client.defaults.headers['X-API-Key'] = cryptApiKey(envVariables.PRIVATE_X_API_KEY);
    const response = await Client.patch(url, data);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};
const update = async (url: string, data) => {
  try {
    Client.defaults.headers['X-API-Key'] = cryptApiKey(envVariables.PRIVATE_X_API_KEY);
    const response = await Client.put(url, data);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// DELETE action
const remove = async (url: string) => {
  try {
    Client.defaults.headers['X-API-Key'] = cryptApiKey(envVariables.PRIVATE_X_API_KEY);
    const response = await Client.delete(url);

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export { get, post, remove, patch, update, getAbsolute };
