import { THE_COMPANY_PAGE_DEFAULT, MEMORIES_PAGE_DEFAULT, MAGAZINES_PAGE_DEFAULT } from './defaultPagesUrls';
import defaultImage from '../assets/images/sliderImage1.png';
import { CARRIER_PAGE, PRESSE_NEWS_PAGE } from './pagesUrls';
import { envVariables } from '../config/config';

export interface MenuInterface {
  title: string;
  url: string;
}

export type MenuItemType = {
  title: string;
  url?: string;
  class?: string;
  id?: number;
  isReservation?: true;
  ref?: string;
  tabId?: string;
};
export type MenuType = {
  title: string;
  url?: string;
  id: number;
  items?: MenuItemType[];
};
export const MENU: MenuType[] = [
  {
    title: 'menu:companion',
    url: `/${THE_COMPANY_PAGE_DEFAULT}`,
    id: 1,
  },
  {
    title: 'menu:memories',
    url: `/${MEMORIES_PAGE_DEFAULT}`,
    id: 2,
  },
  {
    title: 'menu:media',
    url: PRESSE_NEWS_PAGE,
    id: 3,
  },
  {
    title: 'menu:carrier',
    url: CARRIER_PAGE,
    id: 4,
  },
  {
    title: 'menu:magazines',
    url: `/${MAGAZINES_PAGE_DEFAULT}`,
    id: 5,
  },
  {
    title: 'menu:pro_access',
    id: 6,
    items: [
      { title: 'menu:travel_agencies', url: `${envVariables.TRAVEL_AGENCIES_URL}` },
      {
        title: 'menu:tour_operator',
        url: `${envVariables.TOUR_OPERATOR_URL}`,
      },
      { title: 'menu:partner_access', url: `${envVariables.PARTNER_ACCESS_URL}` },
      {
        title: 'menu:aircrew',
        url: `${envVariables.AIRCREW_URL}`,
      },
    ],
  },
];

export const MENU_WEB: MenuType[] = [
  {
    title: 'menu:companion',
    url: `/${THE_COMPANY_PAGE_DEFAULT}`,
    id: 1,
  },
  {
    title: 'menu:memories',
    url: `/${MEMORIES_PAGE_DEFAULT}`,
    id: 2,
  },
  {
    title: 'menu:media',
    url: PRESSE_NEWS_PAGE,
    id: 3,
  },
  {
    title: 'menu:carrier',
    url: CARRIER_PAGE,
    id: 4,
  },
  {
    title: 'menu:magazines',
    url: `/${MAGAZINES_PAGE_DEFAULT}`,
    id: 5,
  },
];
