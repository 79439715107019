import React, { useState } from 'react';
import { Button, Divider, Grid, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { languages } from '../../utils/languages';
import Cookies from 'js-cookie';

const LanguageSelector = () => {
  const { t } = useTranslation('common');
  const { i18n } = useTranslation();
  const router = useRouter();
  const [currentLanguageCode, seCurrentLanguageCode] = useState<string>(router.locale);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const changeLanguage = (language: string) => {
    Cookies.set('NEXT_LOCALE', language);
    seCurrentLanguageCode(language);
    i18n.changeLanguage(language);
    router.push(router.asPath, router.asPath, {
      locale: language,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  return (
    <Grid className="language-selector">
      <Grid alignItems="center" container>
        <Button
          className="language-btn"
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleOpen}
          onMouseOver={() => handleOpen()}
          onMouseLeave={() => handleClose()}
        >
          <span className="language-menu-abv">{currentLanguageCode}</span>
          {open ? <ExpandLessIcon style={{ fontSize: '12px' }} /> : <ExpandMoreIcon style={{ fontSize: '12px' }} />}
          {open && <div className="popper-style"></div>}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal={false}
          className="language-dropdown menu-dropdown"
          onMouseOver={handleOpen}
          onMouseLeave={handleClose}
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
            },
          }}
          placement="bottom"
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'bottom',
              }}
            >
              <Paper>
                <Grid container direction="column" className="languages-list">
                  <div className="list-title">{t('language')}</div>
                  <Divider className="title-underline" />
                  <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {languages.map(({ code, name }, index) => (
                      <MenuItem
                        key={index + code}
                        onClick={() => {
                          handleClose();
                          changeLanguage(code);
                        }}
                        selected={currentLanguageCode == code}
                      >
                        {t(name)}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Grid>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

export default LanguageSelector;
