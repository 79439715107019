export const languages = [
  {
    id: 1,
    code: 'fr',
    name: 'language_fr',
    title: 'titleFr',
  },
  {
    id: 2,
    code: 'en',
    name: 'language_en',
    title: 'titleEn',
  },
];
