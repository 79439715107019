import { get } from '../helpers/apiMethods';
import { SEO_DATA_URL, CORPATE_DATA_URL, SETTINGS_URL } from '../utils/apiUrls';

export const getSettings = async (languageId: number) => {
  const res = get(`${SETTINGS_URL}?language=${languageId}`);
  return res;
};

export const getSEOData = async (languageId: number, pageType: number) => {
  const res = get(`${SEO_DATA_URL}?language_id=${languageId}&type=${pageType}`);
  return res;
};

export const getCorporatedata = async (languageId: number) => {
  const res = get(`${CORPATE_DATA_URL}?language_id=${languageId}`);
  return res;
};
