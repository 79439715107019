import React, { useEffect, useState } from 'react';
import { Grid, Hidden, ListItemText, List, Collapse, ListItem, Button, Fab, IconButton } from '@material-ui/core';
import Logo from '../ui/svg/Logo';
import { useTranslation } from 'next-i18next';
import AnypliLogo from '../ui/svg/AnypliLogo';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { envVariables } from '../../config/config';
import { useStyles } from './styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { footerSections, footerSingleSections, footerMenuSections } from '../../utils/footerSections';
import { PageTypeInterface, FooterSectionsInterface } from '../../interfaces';
import smoothscroll from 'smoothscroll-polyfill';
import { ABV_PAGE } from '../../utils/pagesUrls';
import Link from 'next/link';
import FacebookIcon from '../ui/svg/FacebookIcon';
import TwitterIcon from '../ui/svg/TwitterIcon';
import YoutubeIcon from '../ui/svg/YoutubeIcon';
import InstagramIcon from '../ui/svg/InstagramIcon';
import AppleBtn from '../ui/AppleBtn';
import GoogleBtn from '../ui/GoogleBtn';
import { useRouter } from 'next/router';

const Footer = () => {
  const { t } = useTranslation('footer');
  const classes = useStyles();
  const router = useRouter();
  const currentLanguageCode = router.locale;
  const [open, setOpen] = useState({});

  const handleClick = (index: number) => {
    setOpen({ [index]: !open[index] });
  };

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const scrollUp = () => {
    document.querySelector('body').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Grid container justifyContent="center" alignItems="center" className="footer-container" id="footer">
      <Grid container justifyContent="center" alignItems="center" className="footer-content">
        <Fab aria-label="up" className="up-button" onClick={() => scrollUp()}>
          <ExpandLessIcon />
        </Fab>
        <Grid container justifyContent="center" alignItems="center" className="footer-logo">
          <Logo />
        </Grid>
        <Hidden smDown>
          <List component="nav" aria-labelledby="footer-list" className="footer-list">
            {footerMenuSections.map((footerSingleSection: FooterSectionsInterface, index: number) => (
              <ListItem className="footer-list-container footer-list-contact" key={`footer-list-item-single-${index}`}>
                <List component="nav" aria-labelledby="footer-list">
                  {footerSingleSection?.items?.map((footerSingleSectionItem: PageTypeInterface, indexFooterItem: number) => (
                    <ListItem disableGutters button key={`footer-list-item-single-item-${indexFooterItem}`}>
                      <Link scroll href={footerSingleSectionItem?.url} as={footerSingleSectionItem?.url}>
                        <ListItemText primary={t(footerSingleSectionItem?.title)} className="footer-list-item-title" />
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </ListItem>
            ))}
            {footerSections.map((listItem: FooterSectionsInterface, index: number) => (
              <ListItem className="footer-list-container" key={`footer-list-item-${index}`}>
                <ListItemText primary={t(listItem?.title)} className="footer-list-item-title" />
                <List component="nav" aria-labelledby="footer-list">
                  {listItem?.items?.map(
                    (item: PageTypeInterface, itemIndex: number) =>
                      (!item.id || (item.id && item.url)) && (
                        <ListItem disableGutters key={`footer-list-sub-item-${itemIndex}`} button>
                          <Link scroll href={item.id ? ABV_PAGE + item.url : item.url} as={item.id ? ABV_PAGE + item.url : item.url}>
                            <ListItemText className="footer-list-sub-item-title" primary={t(item.title)} />
                          </Link>
                        </ListItem>
                      ),
                  )}
                </List>
              </ListItem>
            ))}
            {footerSingleSections.map((footerSingleSection: FooterSectionsInterface, index: number) => (
              <ListItem className="footer-list-container footer-list-contact" key={`footer-list-item-single-${index}`}>
                <List component="nav" aria-labelledby="footer-list">
                  {footerSingleSection?.items?.map((footerSingleSectionItem: PageTypeInterface, indexFooterItem: number) => (
                    <ListItem
                      disableGutters
                      button
                      key={`footer-list-item-single-item-${indexFooterItem}`}
                      onClick={() => {
                        footerSingleSectionItem?.title === 'website'
                          ? window.open(`${footerSingleSectionItem?.url}/${currentLanguageCode}`, '_blank')
                          : window.open(`${footerSingleSectionItem?.url}`, '_self');
                      }}
                    >
                      <ListItemText primary={t(footerSingleSectionItem?.title)} className="footer-list-item-title" />
                    </ListItem>
                  ))}

                  {index === footerSingleSections?.length - 1 && (
                    <ListItem disableGutters component="nav">
                      <Grid justifyContent="flex-start" container alignItems="center" className="footer-social-media">
                        <IconButton component="a" href={envVariables.FACEBOOK_URL} rel="noreferrer" aria-label="Facebook">
                          <FacebookIcon />
                        </IconButton>
                        <IconButton component="a" href={envVariables.TWITTER_URL} rel="noreferrer" aria-label="Twitter">
                          <TwitterIcon />
                        </IconButton>
                        <IconButton component="a" href={envVariables.INSTAGRAM_URL} rel="noreferrer" aria-label="INSTAGRAM">
                          <InstagramIcon />
                        </IconButton>
                        <IconButton component="a" href={envVariables.YOUTUBE_URL} rel="noreferrer" aria-label="Youtube">
                          <YoutubeIcon />
                        </IconButton>
                      </Grid>
                    </ListItem>
                  )}
                  <Grid className="download-app-container" container justifyContent="center" alignItems="center">
                    <AppleBtn />
                    <GoogleBtn />
                  </Grid>
                </List>
              </ListItem>
            ))}
          </List>
        </Hidden>
        <Hidden mdUp>
          <List component="nav" aria-labelledby="footer-list" className="footer-list-mobile">
            {footerMenuSections.map((footerSingleSection: FooterSectionsInterface, index: number) => (
              <ListItem className="footer-list-container footer-list-contact" key={`footer-list-item-single-${index}`}>
                <List component="nav" aria-labelledby="footer-list" className="full-width-footer-list">
                  {footerSingleSection?.items?.map((footerSingleSectionItem: PageTypeInterface, indexFooterItem: number) => (
                    <ListItem disableGutters button key={`footer-list-item-single-item-${indexFooterItem}`}>
                      <Link scroll href={footerSingleSectionItem?.url} as={footerSingleSectionItem?.url}>
                        <ListItemText primary={t(footerSingleSectionItem?.title)} className="footer-list-item-title" />
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </ListItem>
            ))}
            {footerSections.map((listItem, index) => (
              <div key={`footer-list-item-${index}`}>
                <ListItem button onClick={() => handleClick(index)}>
                  <ListItemText primary={t(listItem?.title)} className="footer-list-item-title" />
                  {open[index] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open[index]} timeout="auto">
                  <List component="div" disablePadding>
                    {listItem?.items?.map(
                      (item: PageTypeInterface, itemIndex: number) =>
                        (!item.id || (item.id && item.url)) && (
                          <ListItem disableGutters key={`footer-list-sub-item-${itemIndex}`} button>
                            <Link scroll href={item.id ? ABV_PAGE + item.url : item.url} as={item.id ? ABV_PAGE + item.url : item.url}>
                              <ListItemText className="footer-list-sub-item-title" primary={t(item.title)} />
                            </Link>
                          </ListItem>
                        ),
                    )}
                  </List>
                </Collapse>
              </div>
            ))}
            {footerSingleSections.map((footerSingleSection: FooterSectionsInterface, index: number) => (
              <ListItem className="footer-list-container footer-list-contact" key={`footer-list-item-single-${index}`}>
                <List component="nav" aria-labelledby="footer-list" className="full-width-footer-list">
                  {footerSingleSection?.items?.map((footerSingleSectionItem: PageTypeInterface, indexFooterItem: number) => (
                    <ListItem
                      disableGutters
                      button
                      key={`footer-list-item-single-item-${indexFooterItem}`}
                      onClick={() => {
                        footerSingleSectionItem?.title === 'website'
                          ? window.open(`${footerSingleSectionItem?.url}/${currentLanguageCode}`, '_blank')
                          : window.open(`${footerSingleSectionItem?.url}`, '_self');
                      }}
                    >
                      <ListItemText primary={t(footerSingleSectionItem?.title)} className="footer-list-item-title" />
                    </ListItem>
                  ))}
                  {index === footerSingleSections?.length - 1 && (
                    <ListItem disableGutters component="nav">
                      <Grid justifyContent="center" container alignItems="center" className="footer-social-media">
                        <IconButton component="a" href={envVariables.FACEBOOK_URL} rel="noreferrer" aria-label="Facebook">
                          <FacebookIcon />
                        </IconButton>
                        <IconButton component="a" href={envVariables.TWITTER_URL} rel="noreferrer" aria-label="Twitter">
                          <TwitterIcon />
                        </IconButton>
                        <IconButton component="a" href={envVariables.INSTAGRAM_URL} rel="noreferrer" aria-label="INSTAGRAM">
                          <InstagramIcon />
                        </IconButton>
                        <IconButton component="a" href={envVariables.YOUTUBE_URL} rel="noreferrer" aria-label="Youtube">
                          <YoutubeIcon />
                        </IconButton>
                      </Grid>
                    </ListItem>
                  )}
                </List>
              </ListItem>
            ))}
            <Grid className="download-app-container" container justifyContent="center" alignItems="center">
              <AppleBtn />
              <GoogleBtn />
            </Grid>
          </List>
        </Hidden>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="center" className={`footer-info ${classes.footerInfo}`}>
        <Grid item className="footer-rights">
          {t('rights')}
        </Grid>
        <Grid item className="footer-right-side">
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item className="footer-powered-container">
              <Grid item container alignItems="center" justifyContent="flex-end" wrap="nowrap">
                <Grid className="footer-powered">{t('powered')}</Grid>
                <Button component="a" href={envVariables.ANYPLI_URL} rel="noreferrer">
                  <AnypliLogo />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
