import React, { useState } from 'react';
import { Grid, IconButton, Divider, List, ListItem, ListItemText, Select, MenuItem, FormControl, debounce, Collapse } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import Logo from '../ui/svg/Logo';
import CloseIcon from '@material-ui/icons/Close';
import Link from 'next/link';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { languages } from '../../utils/languages';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { Home_PAGE } from '../../utils/pagesUrls';
import { MENU, MenuType } from '../../utils/menu';

export interface MainProps {
  closeMenu: () => void;
}

const MobileHeaderMenu = (props: MainProps) => {
  const { closeMenu } = props;
  const { t } = useTranslation(['menu', 'common']);
  const { i18n } = useTranslation();
  const router = useRouter();
  const currentLanguageCode = router.locale;
  const currentLanguage = languages.find((l) => l.code == currentLanguageCode);
  const [open, setOpen] = useState({});

  const handleChangeLanguage = (code: string) => {
    closeMenu();
    Cookies.set('NEXT_LOCALE', code);
    i18n.changeLanguage(code);
    router.push(router.asPath, router.asPath, {
      locale: code,
    });
  };

  const capitalize = (word: string) => {
    if (word && word.length > 1) {
      return word[0].toUpperCase() + word.substring(1).toLowerCase();
    } else {
      return word;
    }
  };

  const handelItemClick = debounce((item) => {
    closeMenu();
    router.push(item?.url, null, { shallow: true });
  }, 500);

  const handleClick = (index: number) => {
    setOpen({ [index]: !open[index] });
  };

  return (
    <Grid container className="mobile-menu">
      <Grid container alignItems="center" className="mobile-menu-header" wrap="nowrap">
        <Grid className="close-btn">
          <IconButton onClick={() => closeMenu()}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid container justifyContent="center" onClick={closeMenu}>
          <Link href={Home_PAGE} as={Home_PAGE} shallow>
            <a className="header-logo">
              <Logo />
            </a>
          </Link>
        </Grid>
      </Grid>
      <Grid container>
        <List component="nav" aria-labelledby="menu-list" className="collapsed-menu">
          {MENU.map((listItem: MenuType, index: number) => (
            <div key={`menu-list-item-${index}`} className="collapsed-menu-item">
              {listItem.items?.length > 0 ? (
                <>
                  <ListItem button onClick={() => handleClick(index)}>
                    <ListItemText primary={t(listItem.title)} className="collapsed-menu-title" />

                    {open[index] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>

                  <Collapse in={open[index]} timeout="auto">
                    <List component="div" disablePadding className="collapsed-sub-menu">
                      {listItem.items.map((item, itemIndex: number) => (
                        <ListItem
                          disableGutters
                          key={`menu-list-sub-item-${itemIndex}`}
                          button
                          onClick={() => handelItemClick(item)}
                          className={item.class ? 'menu-list-sub-mini-item' : ''}
                        >
                          <ListItemText className="collapsed-sub-menu-title" primary={t(item.title)} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </>
              ) : (
                <ListItem button onClick={() => handelItemClick(listItem)}>
                  <ListItemText primary={t(listItem.title)} className="collapsed-menu-title" />
                </ListItem>
              )}
            </div>
          ))}
        </List>
      </Grid>
      <Grid container className="menu-footer">
        <FormControl variant="outlined" fullWidth className="language-menu-selector">
          <Select
            IconComponent={ExpandMore}
            fullWidth
            value={{
              language: currentLanguage.code,
            }}
            renderValue={() => (
              <Grid container alignItems="center" className="select-value">
                {capitalize(currentLanguage.code)}
              </Grid>
            )}
          >
            <Grid container direction="column" className="select-dropdown">
              <Grid className="select-dropdown-title">
                {t('common:language')}
                <Divider />
              </Grid>

              {languages.map(({ code, name }, index) => (
                <MenuItem
                  selected={code == currentLanguage.code}
                  key={`${name} - ${code} - ${index}`}
                  value={code}
                  onClick={() => handleChangeLanguage(code)}
                >
                  {t(`common:${name}`)}
                </MenuItem>
              ))}
            </Grid>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default MobileHeaderMenu;
