import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Button, Grid } from '@material-ui/core';
import AppleIcon from '../svg/AppleIcon';
import { envVariables } from '../../../config/config';
import { isMobile, isIOS, isTablet } from '../../../utils/deviceDetector';

export const AppleBtn = () => {
  const { t } = useTranslation('menu');
  const [show, setShow] = useState(true);

  const verifyDevice = () => {
    const mobileDevice = isMobile();
    const mobileIOS = isIOS();
    const tabletDevice = isTablet();

    if ((mobileDevice || tabletDevice) && !mobileIOS) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    verifyDevice();
    window.addEventListener('resize', verifyDevice);
    return () => window.removeEventListener('resize', verifyDevice);
  }, []);

  return (
    show && (
      <Button className="blue-button apple-button download-app-btn" component="a" href={envVariables.APP_STORE_URL}>
        <Grid>
          <AppleIcon />
        </Grid>
        <Grid>
          <Grid className="apple-button-download-txt">{t('download_apple')}</Grid>
          <Grid className="apple-button-txt">{t('menu:app_store')}</Grid>
        </Grid>
      </Button>
    )
  );
};

export default AppleBtn;
