export const envVariables = {
  APP_URL: process.env.REACT_APP_URL || '',
  JWT_KEY: process.env.JWT_KEY || '2gyZ3GDw3LHZQKDhPmPDL3sjREVRXPr8',
  BASE_URL_API: process.env.REACT_APP_API_BASE_URL || '',
  URL_API: (process.env.REACT_APP_API_BASE_URL || '') + 'api/',
  TWITTER_URL: process.env.REACT_APP_TWITTER || '',
  FACEBOOK_URL: process.env.REACT_APP_FACEBOOK || '',
  INSTAGRAM_URL: process.env.REACT_APP_INSTAGRAM || '',
  YOUTUBE_URL: process.env.REACT_APP_YOUTUBE || '',
  LINKEDIN_URL: process.env.REACT_APP_LINKEDIN || '',
  ANYPLI_URL: process.env.REACT_APP_ANYPLI || '',
  GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID || '',
  FACEBOOK_MESSANGER_CODE: process.env.REACT_APP_FACEBOOK_MESSANGER_CODE || '',
  PLAY_STORE_URL: process.env.REACT_APP_PLAY_STORE || '',
  APP_STORE_URL: process.env.REACT_APP_APP_STORE || '',
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || '',
  MICROSOFT_CLARITY_ID: process.env.REACT_APP_MICROSOFT_CLARITY_ID || '',
  GTM_ID: process.env.REACT_APP_GTM_ID || '',
  PRIVATE_KEY: process.env.REACT_APP_PRIVATE_KEY || '',
  PRIVATE_IV: process.env.REACT_APP_PRIVATE_IV || '',
  PRIVATE_X_API_KEY: process.env.REACT_APP_PRIVATE_X_API_KEY || '',
  RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY || '',
  REQUIRE_LOGIN: Number(process.env.REACT_APP_REQUIRE_LOGIN || '1'),
  WEB_SITE_URL: process.env.REACT_APP_WEB_SITE_URL || '',
  NOUVELAIR_PHONE_NUMBER: process.env.REACT_APP_PHONE_NUMBER || '+216 70 020 920',
  MAP_KEY: process.env.REACT_APP_MAP_KEY || '',
  MAP_STYLE_URL: process.env.REACT_APP_MAP_STYLE_URL || '',
  INTERNATIONAL_PHONE_NUMBER: process.env.REACT_APP_INTERNATIONAL_PHONE_NUMBER || '',
  CONTACT_SERVICE_URL: process.env.REACT_APP_CONTACT_SERVICE_URL || '',
  TRAVEL_AGENCIES_URL: process.env.REACT_APP_TRAVEL_AGENCIES_URL || '',
  TOUR_OPERATOR_URL: process.env.REACT_APP_TOUR_OPERATOR_URL || '',
  PARTNER_ACCESS_URL: process.env.REACT_APP_PARTNER_ACCESS_URL || '',
  AIRCREW_URL: process.env.REACT_APP_AIRCREW_URL || '',
  ENV: process.env.ENV,
};
