import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  footerInfo: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap-reverse',
      textAlign: 'center',
      '& *': {
        justifyContent: 'center',
        textAlign: 'center',
      },
      '& .footer-powered-container': {
        paddingTop: 10,
        paddingBottom: 10,
        width: '100%',
      },
      '& .footer-rights, & .footer-links-list, & .footer-right-side': {
        width: '100%',
      },
    },
  },
}));
