import React, { createContext, Dispatch, useReducer } from 'react';
import { globalReducer } from './reducers/globalReducer';

type InitialStateType = {
  country: number;
};

// initial state
const initialState = {
  country: 0,
};

// create context
const ReducerContext = createContext<{
  state: InitialStateType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

// combine reducer function
const combineReducers =
  (...reducers) =>
  (state, action) => {
    for (const reducer of reducers) {
      state = reducer(state, action);
    }

    return state;
  };

type Props = {
  children: React.ReactNode;
};
const ReducerProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(combineReducers(globalReducer), initialState);
  const value = { state, dispatch };

  return <ReducerContext.Provider value={value}>{children}</ReducerContext.Provider>;
};

export default ReducerContext;
export { ReducerProvider };
