export const Home_PAGE = '/';
export const LOGIN_PAGE = '/login';
export const NOT_FOUND_PAGE = '/404';
export const ABV_PAGE = '/contenu/';
export const COOKIES_POLICY_PAGE = '/cookies';
export const SITE_MAP_PAGE = '/server-sitemap.xml';
export const SITE_MAP_DISPLAY_PAGE = '/plan-du-site';
export const CONTACT_PAGE = '/contact';
export const CARRIER_PAGE = '/espace-carriere';
export const CANDIDATURE_SPONTANEE = '/candidature-spontanee';
export const INTERNSHIP_APPLICATION = '/demande-stage';
export const PARTNERS_PAGE = '/nos-partenaires';
export const PRESSE_NEWS_PAGE = '/media-space-articles-presse';
export const GALERIE_PAGE = '/galerie';
