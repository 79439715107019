import { envVariables } from '../config/config';

export const espacePro = [
  {
    id: 1,
    url: `${envVariables.TRAVEL_AGENCIES_URL}`,
    title: 'menu:travel_agencies',
  },
  {
    id: 2,
    url: `${envVariables.TOUR_OPERATOR_URL}`,
    title: 'menu:tour_operator',
  },
  {
    id: 3,
    url: `${envVariables.PARTNER_ACCESS_URL}`,
    title: 'menu:partner_access',
  },
  {
    id: 4,
    url: `${envVariables.AIRCREW_URL}`,
    title: 'menu:aircrew',
  },
];
