import { envVariables } from '../config/config';
import { FooterSectionsInterface } from '../interfaces';
import { MAGAZINES_PAGE_DEFAULT, MEMORIES_PAGE_DEFAULT, THE_COMPANY_PAGE_DEFAULT } from './defaultPagesUrls';
import { CARRIER_PAGE, CONTACT_PAGE, Home_PAGE, PRESSE_NEWS_PAGE, SITE_MAP_DISPLAY_PAGE } from './pagesUrls';
import { companySection, discoverSection } from './sections';

export const footerSections: Array<FooterSectionsInterface> = [companySection, discoverSection];

export const footerSingleSections: Array<FooterSectionsInterface> = [
  {
    items: [
      { title: 'footer:contact', url: CONTACT_PAGE },
      { title: 'sitemap', url: SITE_MAP_DISPLAY_PAGE },
      { title: 'website', url: envVariables.WEB_SITE_URL },
    ],
  },
];
export const footerMenuSections: Array<FooterSectionsInterface> = [
  {
    items: [
      { title: 'common:homepage', url: Home_PAGE },
      { title: 'footer:company', url: `/${THE_COMPANY_PAGE_DEFAULT}` },
      { title: 'footer:memories', url: `/${MEMORIES_PAGE_DEFAULT}` },
      { title: 'footer:media_center', url: `/${PRESSE_NEWS_PAGE}` },
      { title: 'footer:carriers_emploi', url: CARRIER_PAGE },
      { title: 'menu:magazines', url: `/${MAGAZINES_PAGE_DEFAULT}` },
    ],
  },
];
