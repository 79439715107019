import React, { useState, useEffect } from 'react';
import { Grid, Hidden, IconButton, SwipeableDrawer } from '@material-ui/core';
import Link from 'next/link';
import Logo from '../ui/svg/Logo';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import LanguageSelector from '../LanguageSelector';
import HeaderMenu from '../HeaderMenu';
import MenuIcon from '@material-ui/icons/Menu';
import MobileHeaderMenu from '../MobileHeaderMenu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Home_PAGE } from '../../utils/pagesUrls';
import SubMenuSelector from '../SubMenuSelector';

const Header = () => {
  const [showScrolling, setShowScrolling] = useState(false);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = (value: boolean) => {
    setOpen(value);
    const body = document.querySelector('body');
    if (value) {
      body.style.height = '100vh';
      body.style.overflowY = 'hidden';
    } else {
      body.style.height = 'auto';
      body.style.overflowY = 'auto';
    }
  };

  const closeMenu = () => {
    const body = document.querySelector('body');
    body.style.height = 'auto';
    setOpen(false);
  };

  useScrollPosition(({ currPos }) => {
    let alertsHeight = 0;
    const alertsContainer = document.querySelector<HTMLElement>('.alerts-container');
    alertsHeight = alertsContainer ? alertsContainer.offsetHeight : 0;

    const sliderHeight = matches ? -200 : -400;

    if (currPos.y < sliderHeight - alertsHeight) {
      setShowScrolling(true);
    } else {
      setShowScrolling(false);
    }
  });

  useEffect(() => {
    // on resize window
    const handleResize = () => {
      const body = document.querySelector('body');
      if (window.innerWidth >= 960 && open) {
        body.style.height = 'auto';
        setOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid className={'menu-container ' + (showScrolling ? 'light-menu' : 'dark-menu')}>
      <Hidden smDown>
        <Grid container alignItems="center" justifyContent="space-between" className="menu-content" wrap="nowrap">
          <Grid>
            <Grid container alignItems="flex-start" justifyContent="space-between" wrap="nowrap">
              <Grid className="menu-logo menu-list-item">
                <Link href={Home_PAGE} shallow>
                  <a className="header-logo">
                    <Logo />
                  </a>
                </Link>
              </Grid>
              <Grid>
                <HeaderMenu />
              </Grid>
            </Grid>
          </Grid>

          <Grid style={{ display: 'flex' }}>
            <Grid alignItems="center" container wrap="nowrap">
              <Grid className="menu-list-item">
                <SubMenuSelector />
              </Grid>
            </Grid>
            <Grid alignItems="center" container wrap="nowrap">
              <Grid className="menu-list-item">
                <LanguageSelector />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid className="menu-content-responsive" container justifyContent="flex-start" alignItems="center">
          <Grid>
            <IconButton onClick={() => toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid className="menu-responsive-logo">
            <Link href={Home_PAGE} as={Home_PAGE} shallow>
              <a>
                <Logo />
              </a>
            </Link>
          </Grid>
        </Grid>
        <SwipeableDrawer anchor="left" open={open} onClose={() => toggleDrawer(false)} onOpen={() => toggleDrawer(true)} className="side-bar">
          <MobileHeaderMenu closeMenu={closeMenu} />
        </SwipeableDrawer>
      </Hidden>
    </Grid>
  );
};

export default Header;
