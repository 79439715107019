import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import GooglePlayIcon from '../svg/GooglePlayIcon';
import { useTranslation } from 'next-i18next';
import { envVariables } from '../../../config/config';
import { isMobile, isAndroid, isTablet } from '../../../utils/deviceDetector';

export const GoogleBtn = () => {
  const { t } = useTranslation('menu');
  const [show, setShow] = useState(true);

  const verifyDevice = () => {
    const mobileDevice = isMobile();
    const mobileAndroid = isAndroid();
    const tabletDevice = isTablet();

    if ((mobileDevice || tabletDevice) && !mobileAndroid) {
      setShow(false);
    } else {
      setShow(true);
    }
  };
  useEffect(() => {
    verifyDevice();
    window.addEventListener('resize', verifyDevice);
    return () => window.removeEventListener('resize', verifyDevice);
  }, []);

  return (
    show && (
      <Button className="blue-button google-button download-app-btn" component="a" href={envVariables.PLAY_STORE_URL}>
        <Grid>
          <GooglePlayIcon />
        </Grid>
        <Grid>
          <Grid className="google-button-download-txt">{t('menu:download_google')}</Grid>
          <Grid className="google-button-txt">{t('menu:google_play')}</Grid>
        </Grid>
      </Button>
    )
  );
};

export default GoogleBtn;
