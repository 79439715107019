/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import CryptoAES from 'crypto-js/aes';
import { envVariables } from '../config/config';
import CryptoENC from 'crypto-js/enc-utf8';
import { MENU_WEB, MenuType } from '../utils/menu';
import { MAX_PHONE_NUMBER, MAX_ZIP_CODE_NUMBER } from '../utils/constants';
import { languages } from '../utils/languages';

/**
 * Validate the email format
 *
 * @param {string} value
 *
 * @returns {boolean}
 */
export const emailIsValid = (value: string): boolean => {
  if (new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/).test(value)) {
    return true;
  } else {
    return false;
  }
};

/**
 * Validate the phone number
 *
 * @param {string} value
 *
 * @returns {boolean}
 */
export const phoneIsValid = (value: string): boolean => {
  if (value.length == MAX_PHONE_NUMBER && !isNaN(parseInt(value))) {
    return true;
  } else {
    return false;
  }
};
/**
 * Validate the zip code number
 *
 * @param {string} value
 *
 * @returns {boolean}
 */
export const zipCodeIsValid = (value: string): boolean => {
  if (value.length == MAX_ZIP_CODE_NUMBER && !isNaN(parseInt(value))) {
    return true;
  } else {
    return false;
  }
};

/**
 * split array into chunks
 *
 * @param arr
 * @param bulkSize
 * @returns
 */
export const devideArrayIntoChunks = <T>(arr: T[], bulkSize: number = 3, firstDiff: number = 0): T[][] => {
  const bulks: T[][] = [];

  const initArray = [...arr];

  if (firstDiff > 0) {
    bulks.push(arr.slice(0, bulkSize - firstDiff));
    initArray.splice(0, bulkSize - firstDiff);
  }

  for (let i = 0; i < Math.ceil(initArray.length / bulkSize); i++) {
    bulks.push(initArray.slice(i * bulkSize, (i + 1) * bulkSize));
  }

  return bulks;
};

/**
 * get future date by number of months
 * @param today
 * @param months
 * @returns
 */
export const getFutureDate = (today: Date, months: number): string => {
  return formatDate(today.setMonth(today.getMonth() + months));
};

/**
 * formatting a date yyyy-mm-dd
 * @param date
 * @returns
 */
export const formatDate = (date: string | number | Date) => {
  const d = new Date(date),
    year = d.getFullYear();

  let month = '' + (d.getMonth() + 1),
    day = '' + d.getDate();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
};

/**
 *
 * @param date
 * @param format
 * @returns
 */
export const formatDateAs = (date: string | number | Date, format: string) => {
  const d = new Date(date);
  return moment(d).format(format);
};

/**
 *
 * @param arr remove duplicated values from array
 * @returns
 */
export const removeDuplicates = (arr: Array<any>) => {
  return arr.filter((thing: any, index: number, self: Array<any>) => index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing)));
};

/**
 *
 * @param seconds
 * @returns
 */
export const secondsToDhms = (seconds: number) => {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  return { days: d, hours: h, minutes: m, seconds: s };
};

/**
 *
 * @param array
 * @param value
 * @returns
 */
export const arrayContains = (array: Array<any>, value: any) => {
  return array.some((code) => JSON.stringify(code) === JSON.stringify(value));
};

/**
 *
 * @param array
 * @param value
 * @returns
 */
export const arrayContainsByValue = (array: Array<any>, value: any, item: string) => {
  return array.some((code) => code[item] === value[item]);
};

/**
 *
 * @param date
 * @param format
 * @returns
 */
export const getCurretDate = (date: string) => {
  return date.split('-').reverse().join('/');
};

/**
 *
 * @param date
 * @param format
 * @returns
 */
export const stripFromHtml = (html: string) => {
  const stripedHtml = html.replace(/(<([^>]+)>)/gi, '');
  return stripedHtml;
};

/**
 *
 * @param value
 * @returns
 */
export const cryptValue = (value: any) => {
  const ciphertext = CryptoAES.encrypt(JSON.stringify(value), envVariables.JWT_KEY);

  return ciphertext.toString();
};

/**
 *
 * @param value
 * @returns
 */
export const decryptValue = (value: string) => {
  const _ciphertext = CryptoAES.decrypt(value, envVariables.JWT_KEY);
  const dycreptedData = _ciphertext.toString(CryptoENC);
  const dycreptedDataObj = JSON.parse(dycreptedData);

  return dycreptedDataObj;
};

/**
 * convert string to json safely
 *
 * @param string
 */
export const getJson = (value: string) => {
  try {
    const json = JSON.parse(value || '{}');
    return json;
  } catch (e) {
    return {};
  }
};

/**
 * get details of corporate item by id
 * @param id
 */
export const getCorporateItem = (id: number): MenuType => {
  return MENU_WEB.find((x) => x.id === id);
};

/**
 * check valid url
 *
 * @param {string} value
 *
 * @returns {boolean}
 */
export const isUrl = (value: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol
      '((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+' + // sub-domain + domain name
      '[a-zA-Z]{2,13})' + // extension
      '|((\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
      '|localhost)' + // OR localhost
      '(\\:\\d{1,5})?' + // port
      '(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*' + // path
      '(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?' + // query string
      '(\\#[-a-zA-Z&\\d_]*)?$',
    'i',
  ); // fragment locator
  return value ? pattern.test(value) : true;
};

/**
 * @param value the single string that shall be masked
 * @return the resulting string with the masked characters
 */
export const replaceChar = (value: string) => {
  if (value.includes('&')) {
    // test if the character appears
    value = value.replace(/&/g, '&amp;');
  }
  if (value.includes('>')) {
    value = value.replace(/>/g, '&gt;');
  }
  if (value.includes('<')) {
    value = value.replace(/</, '&lt;');
  }

  if (value.includes('"')) {
    value = value.replace(/"/, '&quot;');
  }

  if (value.includes("'")) {
    value = value.replace(/'/, '&apos;');
  }

  return value;
};

/**
 * verify url to redirect
 * @param url
 */
export const redirectPage = (url: string): { localUrl: string; externalUrl: string } => {
  let localUrl = '';
  let externalUrl = '';
  if (!isUrl(url)) {
    localUrl = url && url[0] == '/' ? url : `/${url}`;
  } else if (url.indexOf(envVariables.APP_URL) === 0) {
    let pageUrl = url.replace(envVariables.APP_URL, '');

    for (const language of languages) {
      if (pageUrl.indexOf(`${language.code}/`) === 0) {
        pageUrl = pageUrl.replace(`${language.code}`, '');
        break;
      }
    }
    localUrl = pageUrl;
  } else {
    externalUrl = url;
  }
  return {
    localUrl,
    externalUrl,
  };
};

/**
 *
 * @param lat
 * @returns
 */
export const isLatitude = (lat: number) => {
  return isFinite(lat) && Math.abs(lat) <= 90;
};

/**
 *
 * @param lat
 * @returns
 */
export const isLongitude = (lng: number) => {
  return isFinite(lng) && Math.abs(lng) <= 180;
};

const specialEntity = [
  { entity: '&eacute;', value: 'é' },
  { entity: '&egrave;', value: 'è' },
  { entity: '&ecirc;', value: 'ê' },
  { entity: '&agrave;', value: 'à' },
  { entity: '&ccedil;', value: 'ç' },
  { entity: '&icirc;', value: 'î' },
  { entity: '&icirc;', value: 'î' },
  { entity: '&iuml;', value: 'ï' },
  { entity: '&ocirc;', value: 'ô' },
  { entity: '&ugrave;', value: 'ù' },
];

export const encodeHtmlEntity = (word: string) => {
  specialEntity.forEach((element) => {
    if (word.includes(element.value)) {
      word = word.replaceAll(new RegExp(element.value, 'gi'), element.entity);
    }
  });

  return word;
};
export const decodeHtmlEntity = (word: string) => {
  specialEntity.forEach((element) => {
    if (word.includes(element.entity)) {
      word.replaceAll(new RegExp(element.entity, 'gi'), element.value);
    }
  });
  return word;
};
