import { CurrencyInterface } from '../../interfaces/currency';
import { OtherSettingsInterface } from '../../interfaces/setting';
import { LocationInterface } from '../../interfaces/location';
import { COUNTRY_TYPE, CURRENCY_TYPE, SLIDER_TYPE } from './types';

interface globalStat {
  snackBar: boolean;
  openSnackBar: (sevirity: string, text: string) => void;
  closeSnackBar: () => void;
  snackBarSevirity: string;
  snackBarTxt: string;
  setReviewTop: () => void;
  handelSearch: (value: boolean) => void;
  openSearch: boolean;
  selectedBloc: string;
  handelChangeSelectedBloc: (value: string) => void;
  selectedTab: string;
  handelChangeSelectedTab: (value: string) => void;
  currencies: Array<CurrencyInterface>;
  countries: Array<LocationInterface>;
  setCurrencies: (value: Array<CurrencyInterface>) => void;
  setCountries: (value: Array<LocationInterface>) => void;
  setSettings: (value: OtherSettingsInterface | null) => void;
  settings: OtherSettingsInterface;
  settingsLoading: boolean;
  setSettingsLoading: (value: boolean) => void;
}

interface ActionProps {
  payload: globalStat;
  type: typeof COUNTRY_TYPE | typeof CURRENCY_TYPE | typeof SLIDER_TYPE;
}

export function globalReducer(state: globalStat, action: ActionProps) {
  switch (action.type) {
    case COUNTRY_TYPE:
      return { ...state, country: action.payload };
    case CURRENCY_TYPE:
      return { ...state, currency: action.payload };
    case SLIDER_TYPE:
      return { ...state, slider: action.payload };
    default:
      return state;
  }
}
